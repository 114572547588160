import classes from "./Dashboard.module.css"
import bin from "./IMG/bin.png"
import wallet from "./IMG/meal.png"
import wallet2 from "./IMG/wallet.png"
import React, { useEffect, useState } from "react";
import axios from "axios";
import cookies from "js-cookie";
import DeletePopup from "../Home/popup/DeletePopup";
import Cookies from "js-cookie";
import useRazorpay from "react-razorpay";
import { v4 as uuidv4 } from 'uuid';
import gpay from "./IMG/gpay.png"
import imgLoader from "./IMG/loading.gif"
import { useNavigate } from 'react-router-dom';
import cancel from './IMG/cancel.png'

import WalletHist from "./WalletHist";



const Dashboard2 = ({ txt1, txt2, onClose, onDelete }) => {
    const [Razorpay] = useRazorpay();
    const [walletMoney, setWalletMoney] = useState(1)
    const [showForm, setShowForm] = useState(false)
    const [pinValid, setPinValid] = useState(false)

    const [data, setData] = useState([]);
    const [userHistory, setUserHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false)
    const [userInfo, setUserInfo] = useState([]);
    const [addInfo, setAddInfo] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [orderIdToDelete, setOrderIdToDelete] = useState(null);
    const [addPopup, setAddPopup] = useState(false)
    const [succpopup, setSuccpopup] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [dummy, setDummy] = useState(false)
    const [totalQuantities, setTotalQuantities] = useState(0)
    const [isLoading, setIsLoading] = useState(true);

    const [minmumDate, setMinimumDate] = useState('')
    const [trustedCurrentTime, setTrustedCurrentTime] = useState(null);

    //processing popup
    const [processing, setProcessing] = useState(false)
    //get my cart data
    const getData = async () => {
        const id = cookies.get("CID");
        const res = await axios.get("https://dlunchbox.com/userDashboard", {
            params: { id },
        });
        console.log(res.data);
        setData(res.data);
        // Initialize quantities with the default quantity for each item
        const initialQuantities = res.data.reduce((acc, item) => {
            acc[item.orderid] = 1; // assuming the default quantity is 1
            return acc;
        }, {});
        setQuantities(initialQuantities);

    };

    const showWalletForm = () => {
        setShowForm(true)
        setShowHistory(false)
    }

    const showWalletForm2 = () => {
        setShowHistory(!showHistory)
        setShowForm(false)
    }

    const addMoneyHnd = (e) => {
        if (e.target.value < 0) {
            e.target.value = ""
            return;
        }
        setWalletMoney(e.target.value)
    }

    const addMoneySbt = async (e) => {
        e.preventDefault()
        let valid_days = 365

        try {
            const Cid = Cookies.get("CID")
            const amount = walletMoney * 100;
            const currency = 'INR';
            const receiptId = uuidv4();

            if (!Cookies.get("CID")) {
                const newTabUrl = 'http://localhost:3000/login';
                // Open a new tab with the specified URL
                window.open(newTabUrl, '_blank');
            } else {
                const response = await fetch('https://dlunchbox.com/order', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        amount,
                        currency,
                        receipt: receiptId
                    })
                })

                const order = await response.json();
                console.log("order", order);

                var option = {
                    key: '',
                    amount,
                    currency,
                    name: 'D LunchBox',
                    description: 'Transaction',
                    image: 'https://dlunchbox.com/static/media/logo.5374deb337fb4bd1dd95.png',
                    order_id: order.id,
                    handler: async function (response) {
                        const body = { ...response }

                        const validateResponse = await fetch("https://dlunchbox.com/validate", {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        const jsonResponse = await validateResponse.json();


                        if (jsonResponse) {
                            //console.log(price, "PPRICE")
                            const CID = Cookies.get("CID")
                            try {
                                const res = await axios.put("https://dlunchbox.com/userOfferPlaced", { CID, walletMoney, valid_days })
                                if (res.status == 200) {
                                    alert('Money Added successfully')
                                    setDummy(!dummy)
                                }
                            } catch (err) {
                                console.log(err)
                            }
                            alert("PAYMENT SUCCESSFUL")
                        }
                    },
                    theme: {
                        color: "#3399cc"
                    },
                }

                var rzp1 = new Razorpay(option);
                rzp1.on("payment.failed", function (response) {
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
                })

                rzp1.open();
                //event.preventDefault();
            }
        } catch (err) {
            console.log(err)
        }

    }

    //get my user INFO
    const getUserinfo = async () => {
        try {
            const id = cookies.get("CID");
            const res = await axios.get("https://dlunchbox.com/userDashInfo", {
                params: { id },
            });
            console.log(res.data);
            setUserInfo(res.data);
            setAddInfo(res.data[0])
            // Set initial deliveryINFO state with user info
            setDeliveryINFO({
                ADD: res.data[0]?.deliveryaddress || '',
                DATE: '',
                TIME: '',
                TOWN: res.data[0]?.village || '',
                CITY: res.data[0]?.city || '',
                PINCODE: res.data[0]?.pincode || '',
                LANDMARK: res.data[0]?.landmark || ''
            });
        } catch (err) {
            console.log(err)
        }

    };

    //get order history
    const getHistory = async () => {
        try {
            const id = cookies.get("CID");
            const res = await axios.get("https://dlunchbox.com/userHistory", {
                params: { id },
            });
            console.log(res.data);
            setUserHistory(res.data);
        } catch (err) {
            console.log(err)
        }

    };

    // Function to get user's current location
    const getCurrentLocation = (e) => {
        e.preventDefault()
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser.');
            return;
        }

        setLoading(true);
        setError(null);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                if (isValidCoordinates(latitude, longitude)) {
                    setLocation({ latitude, longitude });
                    fetchAddress(latitude, longitude);
                } else {
                    setError('Invalid coordinates.');
                    setLoading(false);
                }
            },
            () => {
                setError('Unable to retrieve your location.');
                setLoading(false);
            }
        );
    };
    const fetchAddress = (lat, lng) => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
        const headers = {
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36'
        };

        fetch(url, { headers })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data && data.address) {
                    let fullAddress = '';
                    if (data.address.road) fullAddress += 'Road: ' + data.address.road + ', ';
                    if (data.address.house_number) fullAddress += 'House Number: ' + data.address.house_number + ', ';
                    if (data.address.suburb) fullAddress += 'Suburb: ' + data.address.suburb + ', ';
                    if (data.address.neighbourhood) fullAddress += 'Neighbourhood: ' + data.address.neighbourhood + ', ';
                    if (data.address.village) fullAddress += 'Village: ' + data.address.village + ', ';
                    if (data.address.nearby) fullAddress += 'Nearby: ' + data.address.nearby + ', ';
                    if (data.address.landmark) fullAddress += 'Landmark: ' + data.address.landmark + ', ';
                    if (data.address.city || data.address.town) fullAddress += (data.address.city || data.address.town) + ', ';
                    if (data.address.postcode) fullAddress += 'Pincode: ' + data.address.postcode + ', ';

                    setAddress(fullAddress);
                } else {
                    setError('Failed to get address.');
                }
            })
            .catch(() => {
                setError('Failed to get address.');
                setLoading(false);
            });
    };

    const isValidCoordinates = (lat, lng) => {
        return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
    };



    //delete card
    const userDelHandle = (orderId) => {
        setOrderIdToDelete(orderId)
        setShowDeleteConfirmation(true)
    }
    const closeDelPop = () => {
        setShowDeleteConfirmation(false)
    }
    const deleteDelPop = async () => {
        console.log(orderIdToDelete)
        try {
            const res = await axios.delete("https://dlunchbox.com/UserDeleteDish", { data: { orderIdToDelete } })
            console.log(res)
            setShowDeleteConfirmation(false)
            setDummy(!dummy)
        } catch (err) {
            console.log(err)
        }
    };
    //place order
    const placeOrder = () => {
        setAddPopup(true)
        //alert("We are only taking bulk orders (minimum quantity 20 orders. Call on customer care number) till 4th September 2024 (Not Taking Single Orders). Sorry for the inconvenience")

    };
    const closeaddpop = () => {
        setAddPopup(false)
        window.location.reload()
    }
    const [deliveryINFO, setDeliveryINFO] = useState({
        ADD: addInfo?.deliveryaddress || '',
        DATE: '',
        TIME: '',
        TOWN: addInfo?.village || '',
        CITY: addInfo?.city || '',
        PINCODE: addInfo?.pincode || '',
        LANDMARK: addInfo?.landmark || ''
    });
    const handlePOP = (e) => {
        // if (e.target.name == 'DATE') {
        //     setDeliveryINFO({ ...deliveryINFO, [deliveryINFO.TIME]: "" });
        // }
        // setDeliveryINFO({ ...deliveryINFO, [e.target.name]: e.target.value });

        const { name, value } = e.target;

        // Determine if DATE field is being updated
        const isDateChanged = name === 'DATE';

        // Prepare the updated deliveryINFO object
        const updatedDeliveryINFO = {
            ...deliveryINFO,
            [name]: value
        };

        // If DATE is changed, clear TIME field
        if (isDateChanged) {
            if (e.target.value == '2024-09-18' || e.target.value == '2024-09-17') {
                alert("we are not taking orders on 17th and 18th sept. Please choose another date")
                return
            }
            updatedDeliveryINFO.TIME = "";
        }

        // Set the new state
        setDeliveryINFO(updatedDeliveryINFO);
    };

    const username = addInfo?.name;
    const usermobile = addInfo?.mobno;

    const [paymentMethod, setPaymentMethod] = useState('online'); // default to 'online' payment
    const handlePOPSUBMIT = async (event) => {
        event.preventDefault();
        console.log(totalPrice, username, usermobile, "opo")

        const qnty = quantities
        console.log(qnty, "UUU")

        if (paymentMethod === 'wallet') {
            console.log("Random");
            const tiffin = totalPrice / 90;
            const amount2 = adjustedTotalPrice
            console.log(tiffin, "tiffin")
            const CID = Cookies.get("CID")
            const qnty = quantities
            console.log(qnty, "UUU")
            setProcessing(true)
            try {
                const res = await axios.post("https://dlunchbox.com/paywithwallet2", { amount2, CID, qnty, deliveryINFO, msg2: 'paid' })
                console.log(res)
                if (res.status == 200) {
                    setProcessing(false)
                    setSuccpopup(true)
                } else {
                    setProcessing(false);
                    alert('Network issue OR Insufficient wallet Balance')
                }


            } catch (err) {
                console.log(err)
                console.log(err.response.data, "OLL")
                alert(err.response.data)
                setProcessing(false)
            }
            return;
        }


        const amount = adjustedTotalPrice * 100;  // adjustedTotalPrice includes the additional charge for 8-10 PM delivery
        const currency = 'INR';
        const receiptId = uuidv4();;

        const response = await fetch('https://dlunchbox.com/order', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                amount,
                currency,
                receipt: receiptId
            })
        })

        const order = await response.json();
        console.log("order", order);

        var option = {
            key: '',
            amount,
            currency,
            name: 'D LunchBox',
            description: 'Transaction',
            image: 'https://dlunchbox.com/static/media/logo.5374deb337fb4bd1dd95.png',
            order_id: order.id,
            handler: async function (response) {
                const body = { ...response }

                const validateResponse = await fetch("https://dlunchbox.com/validate", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })

                const jsonResponse = await validateResponse.json();

                console.log('jsonResponse', jsonResponse);
                if (jsonResponse) {
                    console.log(deliveryINFO)
                    console.log(jsonResponse, "PPP")
                    console.log(jsonResponse.msg2, "msg2")
                    const msg2 = jsonResponse.msg2;
                    const razorpay_order_id = jsonResponse.razorpay_order_id;
                    const razorpay_payment_id = jsonResponse.razorpay_payment_id;
                    console.log(jsonResponse.razorpay_order_id, "razorpay_order_id")
                    console.log(jsonResponse.razorpay_payment_id, "razorpay_payment_id")
                    const CID = Cookies.get("CID")
                    const q = calculateTotalQuantities();
                    console.log(q, "ooooooooo")
                    const qnty = quantities
                    console.log(qnty, "UUU")
                    try {
                        const res = await axios.put("https://dlunchbox.com/userOrderPlace", { CID, deliveryINFO, address, msg2, razorpay_order_id, razorpay_payment_id, q, qnty })
                        console.log(res)
                        setSuccpopup(true)

                    } catch (err) {
                        console.log(err)
                    }
                }
            },
            prefill: {
                name: username,
                contact: usermobile,
            },
            theme: {
                color: "#3399cc"
            },
        }

        var rzp1 = new Razorpay(option);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        })

        rzp1.open();
        event.preventDefault();

    }


    //success pop
    const closeSuccessPop = () => {
        setSuccpopup(false)
        window.location.reload();
    }

    const getPincodeValidity = async () => {
        const id = cookies.get("CID");
        const res = await axios.post("https://dlunchbox.com/userPinValidity", { id })
        if (res.status == 200) {
            setPinValid(true)
        }
    }

    useEffect(() => {
        //getTrustedCurrentTime()
        getData();
        getHistory();
        getUserinfo();
        getPincodeValidity()

        const computedMinDate = handleDateValidation();
        setMinimumDate(computedMinDate);
    }, [dummy]);

    useEffect(() => {
        getTrustedCurrentTime();
    }, []);

    useEffect(() => {
        if (trustedCurrentTime) {
            const computedMinDate = handleDateValidation();
            setMinimumDate(computedMinDate);
        }
    }, [trustedCurrentTime]); // Depend on trustedCurrentTime



    // Calculate total price
    const totalPrice = data.reduce((acc, item) => acc + (parseInt(item.price) * (quantities[item.orderid] || 1)), 0);

    //handle nav BTN
    const [navStore, setNavStore] = useState('Cart')
    const handleNav_Btn = (e) => {
        setNavStore(e.target.value)
    };

    //user current location
    const [location, setLocation] = useState(null);
    const [address, setAddress] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //current time from server api


    const getTrustedCurrentTime = async () => {
        try {
            const response = await fetch('https://worldtimeapi.org/api/timezone/Asia/Kolkata');
            const data = await response.json();
            setTrustedCurrentTime(new Date(data.datetime));
        } catch (error) {
            console.error('Error fetching trusted time:', error);
            setTrustedCurrentTime(new Date()); // Fallback to system time if the API fails
        } finally {
            setIsLoading(false);
        }
    };
    // Check if the current time is past 09:30 AM
    const isPastCutoffTime = () => {
        const now = new Date(trustedCurrentTime);
        const cutoffTime = new Date();
        cutoffTime.setHours(9, 30, 0, 0);
        return now > cutoffTime;
    };

    const getCurrentDate = () => {
        const today = new Date(trustedCurrentTime);
        return today.toISOString().split('T')[0];
    };
    // Handle date validation for input
    // const handleDateValidation = () => {
    //     const currentDate = new Date();
    //     const currentHour = currentDate.getHours();
    //     const today = currentDate.toISOString().split('T')[0];

    //     let minDate;
    //     if (currentHour < 16) { // before 4 PM
    //         minDate = today;
    //     } else { // after 4 PM
    //         currentDate.setDate(currentDate.getDate() + 1);
    //         minDate = currentDate.toISOString().split('T')[0];
    //     }

    //     return minDate;
    // };
    const handleDateValidation = () => {
        const currentDate = new Date(trustedCurrentTime);
        const currentHour = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();
        const today = currentDate.toISOString().split('T')[0];

        let minDate;
        if (currentHour < 16 || (currentHour === 16 && currentMinutes < 0)) { // before 4:00 PM
            minDate = today;
        } else { // after 4:00 PM
            currentDate.setDate(currentDate.getDate() + 1);
            minDate = currentDate.toISOString().split('T')[0];
        }

        const skipDates = [
            '2024-09-17', // 17th September 2024
            '2024-09-18'  // 18th September 2024
        ];

        // Ensure minDate is not one of the skip dates
        while (skipDates.includes(minDate)) {
            currentDate.setDate(currentDate.getDate() + 1);
            minDate = currentDate.toISOString().split('T')[0];
        }

        return minDate;
    };


    const minDate = handleDateValidation();


    //cookie security
    const id = cookies.get("CID");

    //formated date
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    }
    //formated date
    function formatDate2(dateString) {
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // for 12-hour format, set to false for 24-hour format
        };
        return date.toLocaleString('en-GB', options).replace(/ /g, ' ');
    }

    //quantity

    const incrementQuantity = (orderId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [orderId]: (prevQuantities[orderId] || 1) + 1
        }));
    };

    const decrementQuantity = (orderId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [orderId]: Math.max((prevQuantities[orderId] || 1) - 1, 1)
        }));
    };
    const calculateTotalQuantities = () => {
        let totalQuantities = 0;
        data.forEach(item => {
            totalQuantities += quantities[item.orderid] || 1;
        });
        return totalQuantities;
    };

    // Calculate the additional charge based on delivery time
    const additionalCharge = deliveryINFO.TIME === "7 - 10 PM" ? 21 * calculateTotalQuantities() : 0;
    const adjustedTotalPrice = totalPrice + additionalCharge;



    return (

        <>
            {id &&
                <div className={classes.container}>
                    <div className={classes.left}>
                        <div className={classes.navtab}>
                            <button className={navStore === "Cart" ? classes.cartbtn : classes.cartbtn2} type="button" value="Cart" onClick={handleNav_Btn} >Cart</button>
                            <button className={navStore === "My orders" ? classes.cartbtn : classes.cartbtn2} type="button" value="My orders" onClick={handleNav_Btn}>My orders</button>
                            <button className={navStore === "My Wallet" ? classes.cartbtn : classes.cartbtn2} type="button" value="My Wallet" onClick={handleNav_Btn}>My Wallet</button>
                        </div>
                        {navStore === "Cart" &&
                            <>
                                <h1 className={classes.h1head}>Your Customize Order</h1>
                                <div className={classes.underline}></div>
                                {data && data.map((item, index) => (
                                    <div className="border border-solid  rounded-xl shadow-xl">
                                        <div className={classes.card}>
                                            <div className={classes.L1}>
                                                <p className={classes.boldID}>Order Id: {item.orderid}</p>
                                                <p className={classes.boldID}>Meal Type: {item.mealtype}</p>
                                                <div className={classes.row}>
                                                    <p className={classes.dish_HIGH}>{item.vegetable1}</p>
                                                    {item.vegetable2 && <p className={classes.dish_HIGH2}>{item.vegetable2}</p>}
                                                    <p className={classes.dish_HIGH3}>{item.bread}</p>
                                                    <p className={classes.dish_HIGH4}>{item.rice}</p>
                                                    <p className={classes.dish_HIGH5}>{item.sweet}</p>
                                                    {item?.extraChapati > 0 && <p className={classes.dish_HIGH5}>extra-Chapati-{item.extraChapati}</p>}
                                                    {item?.extraBhakri > 0 && <p className={classes.dish_HIGH5}>extra Bhakri-{item.extraBhakri}</p>}

                                                </div>
                                            </div>
                                            <div className={classes.R1}>
                                                <img onClick={() => userDelHandle(item.orderid)} src={bin} alt="" />
                                                <div className={classes.qrow}>
                                                    <button className={classes.qbtn} onClick={() => decrementQuantity(item.orderid)}>-</button>
                                                    {quantities[item.orderid] || 1}
                                                    <button className={classes.qbtn} onClick={() => incrementQuantity(item.orderid)}>+</button>
                                                </div>
                                                <p className={classes.price}>₹ {item.price * (quantities[item.orderid] || 1)}</p>
                                            </div>
                                        </div>
                                        {showDeleteConfirmation && <DeletePopup txt1="Are you sure you want to delete your dish" onClose={closeDelPop} onDelete={deleteDelPop} />}
                                        <div>
                                            <hr></hr>
                                            {/* <p className="text-center">Add extra breads with this meal</p> */}
                                        </div>
                                    </div>
                                ))}


                                <div className={classes.btnContainer}>
                                    <p className={classes.Totalprice}>Total Price: ₹ {totalPrice}</p>
                                    <p>Total Quantities: {calculateTotalQuantities()}</p>
                                    {data.length > 0 && <button onClick={placeOrder}>Place Order</button>}
                                    {pinValid && <p className="mt-2 px-2 font-medium bg-red-100 text-red-600">Temporarily, We Are Not Delivering At Your Pincode</p>}
                                </div>
                            </>
                        }{/*For Cart end */}

                        {navStore === "My orders" &&
                            <>
                                <h1 className={classes.h1head}>My Order History</h1>
                                <div className={classes.underline}></div>
                                {userHistory && userHistory.map((item, index) => (
                                    <>
                                        <div className={classes.card}>
                                            <div className={classes.L1}>
                                                <p className={classes.boldID}>Order Id: {item.orderid}</p>
                                                <p className={classes.boldID}>Meal Type: {item.mealtype}</p>
                                                <p className={classes.boldID}>Status: <span className={classes.statu}>Order placed Successfully</span></p>
                                                <p>Order Date : {formatDate2(item.datetime)} </p>
                                                <p>Delivery Date: {item.deliverydate} : {item.deliverytime}</p>
                                                <div className={classes.row}>
                                                    <p className={classes.dish_HIGH}>{item.vegetable1}</p>
                                                    {item.vegetable2 && <p className={classes.dish_HIGH}>{item.vegetable2}</p>}
                                                    <p className={classes.dish_HIGH}>{item.bread}</p>
                                                    <p className={classes.dish_HIGH}>{item.rice}</p>
                                                    <p className={classes.dish_HIGH}>{item.sweet}</p>

                                                </div>
                                            </div>
                                            <div className={classes.R1}>

                                                <p className={classes.price}>₹ {item.price}</p>
                                                <p>{item.quantities}</p>
                                            </div>
                                        </div>
                                        {showDeleteConfirmation && <DeletePopup txt1="Are you sure you want to delete your dish" onClose={closeDelPop} onDelete={deleteDelPop} />}
                                    </>
                                ))}
                            </>
                        }{/*For my order end */}

                        {navStore === "My Wallet" &&
                            <>
                                <h1 className={classes.h1head}>My Wallet</h1>
                                <div className={classes.underline}></div>
                                {userInfo && userInfo.map((item, index) => (
                                    <div key={index} className={classes.walletContainer}>
                                        <img src={wallet} alt="wallet" />
                                        <p className={classes.wallet_balance}>Wallet LunchBox : {item.my_wallet} Balance</p>
                                        {/* <p>Expiry Date : {formatDate(item.wallet_expiration_date)} </p> */}

                                        <div className="flex flex-row justify-center gap-4">
                                            <button onClick={showWalletForm} className="bg-orange-500 px-4 py-4 text-white rounded">Add Money To Wallet</button>
                                            <button onClick={showWalletForm2} className="bg-orange-500 px-4 py-4 text-white rounded">Wallet History</button>
                                        </div>

                                        {showForm && <div className="mt-4 border border-solid bg-gray-200 py-4 px-4 rounded">
                                            <button onClick={() => { setShowForm(false) }} className="w-6 "><img src={cancel} className=""></img></button>
                                            <form onSubmit={addMoneySbt}>
                                                <label className="mr-4">Enter Amount:</label>
                                                <input onChange={addMoneyHnd} type='number' placeholder="Enter Amount" className=" rounded"></input><br></br>
                                                <button type="submit" className="bg-orange-500 mx-auto block mt-8 px-4 py-2 rounded">Make Payment</button>
                                            </form>
                                        </div>}

                                        {showHistory && <div>
                                            <WalletHist></WalletHist>
                                        </div>}
                                    </div>
                                ))}
                            </>
                        }{/*For my order end */}


                    </div>
                    <div className={classes.right}>
                        <div>
                            <h1 className={classes.h1head}>Account Info</h1>
                            <div className={classes.underline}></div>
                            {userInfo && userInfo.map((item, index) => (
                                <div className={classes.infoContainer}>
                                    <p>Name: {item.name}</p>
                                    <p>mobile No.: {item.mobno}</p>
                                    <p>Address: {item.deliveryaddress}</p>
                                    <p>Village: {item.village}</p>
                                    <p>City: {item.city}</p>
                                    <p>Landmark: {item.landmark}</p>
                                    <p>Pincode: {item.pincode}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }


            {addPopup && (
                <div className={classes.addresspopup}>
                    <form className={classes.formContainer} onSubmit={handlePOPSUBMIT}>
                        <p className={classes.cancel} onClick={closeaddpop}>x</p>
                        {/* <button className={classes.currentLocationbtn} onClick={getCurrentLocation} disabled={loading}>
                            {loading ? "Locating .." : "Use Current Location"}
                        </button>
                        {error && <p>{error}</p>} */}

                        <label>Enter Delivery Address</label>
                        {address && <p className={classes.cLocation}>{address}</p>}

                        {!address && (
                            <>
                                <input type="text" name="ADD" placeholder="Address" defaultValue={addInfo.deliveryaddress} onChange={handlePOP} required />
                                <div className={classes.row}>
                                    <input placeholder="Town/Village" defaultValue={addInfo.village} onChange={handlePOP} name="TOWN" required />
                                    <select defaultValue={addInfo.city || "Pune"} onChange={handlePOP} name="CITY" required>
                                        <option value="Pune">Pune</option>
                                        <option value="PCMC">PCMC</option>
                                    </select>
                                </div>
                                <div className={classes.row}>
                                    <input defaultValue={addInfo.pincode} onChange={handlePOP} name="PINCODE" placeholder="pincode" required />
                                    <input onChange={handlePOP} name="LANDMARK" defaultValue={addInfo.landmark} placeholder="Landmark" required />
                                </div>
                            </>
                        )}


                        {isPastCutoffTime() && <p className={classes.timelimit}>Delivery for today after 09:30 AM is available only from  7-10 PM with 30rs delivery charges. Order upto 4.00PM</p>}
                        <label>Enter Delivery Date</label>
                        {isLoading ? (<p>Loading ...</p>) : (<input type="date" name="DATE" onChange={handlePOP} min={minmumDate} required />)}

                        {deliveryINFO.DATE && <>
                            <label>Select Delivery Time</label>
                            <p className="text-sm">We are not taking dinner orders till 18th-Sept-2024. Lunch is available</p>
                            <div className={classes.rowtime}>
                                {(deliveryINFO.DATE === getCurrentDate() && isPastCutoffTime()) ? (
                                    <div className={classes.rowt2}>
                                        <input type="radio" name="TIME" value="7 - 10 PM" onChange={handlePOP} required />
                                        <p>7 - 10 PM</p>
                                    </div>
                                ) : (
                                    <>
                                        <div className={classes.rowt2}>
                                            <input type="radio" name="TIME" value="12 - 2 PM" checked={deliveryINFO.TIME === "12 - 2 PM"} onChange={handlePOP} required />
                                            <p>12 - 2 PM</p>
                                        </div>
                                        <div className={classes.rowt2}>
                                            <input type="radio" name="TIME" value="1 - 3 PM" checked={deliveryINFO.TIME === "1 - 3 PM"} onChange={handlePOP} required />
                                            <p>1 - 3 PM</p>
                                        </div>
                                        {/* <div className={classes.rowt2}>
                                            <input type="radio" name="TIME" value="2 - 4 PM" onChange={handlePOP} required />
                                            <p>2 - 4 PM</p>
                                        </div> */}
                                        <div className={classes.rowt2}>
                                            <input type="radio" name="TIME" value="7 - 10 PM" onChange={handlePOP} required />
                                            <p>7 - 10 PM</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>}


                        {deliveryINFO.TIME !== "" && <button className={classes.walletbtn} type="submit" onClick={() => setPaymentMethod('wallet')}>
                            Pay with Wallet <img src={wallet2} alt="wallet" />{addInfo?.my_wallet}
                        </button>}
                        {deliveryINFO.TIME !== "" && <button className={classes.onlinebtn} type="submit" onClick={() => setPaymentMethod('online')}>
                            Online Payment <img src={gpay} alt="gpay" />
                        </button>}
                    </form>
                </div>
            )}

            {succpopup && <div className={classes.successPopup}>
                <div className={classes.subsuccessPopup}>
                    <p className={classes.succ}>Your Order Placed successfully</p>
                    <p>now You can track your orders from my dashboard</p>
                    <button onClick={closeSuccessPop}>Close</button>
                </div>
            </div>}

            {processing && <div className={classes.successPopup}>
                <div className={classes.subsuccessPopup}>
                    <p className={classes.succ}>processing..</p>
                    <img className={classes.loading} src={imgLoader} alt="loading" />
                    <p>Please wait do not press Back/Refresh button</p>
                </div>
            </div>}
        </>
    );
};
export default Dashboard2;