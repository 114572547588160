import React from 'react';
import Typewriter from 'typewriter-effect';

const Typing = () => {
    return (
        <div className='text-center mt-4 text-2xl font-semibold'>
            <Typewriter
                options={{
                    strings: ['Customise your lunch box at no extra cost, starting at only Rs.99', 'Delight delivered to your doorstep.', 'Order Healthy And Fresh Food Any Time', 'We Provide Service In Pune City & PCMC'],
                    autoStart: true,
                    loop: true,
                }}
            />
        </div>
    );
};

export default Typing;
